.slider {
  width: 100%;
  padding-left: 1rem;
  /* margin-bottom: -10px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.partner_title {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 2rem 0;
  padding-left: 4rem;
}
.partner_title h2 {
  color: #fcab6e;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.partner_title span {
  color: black;
}
.slide_cards {
  display: flex;
  align-items: end;
  border-radius: 5px;
  width: auto !important;
  border: 0.2px solid none;
}

.slide_cards img {
  border: 0.2px solid none;
}

.slick-arrow {
  display: none !important;
}
.slick-prev {
  display: none !important;
  left: 0;
}
