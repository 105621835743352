.slogan {
  color: black;
  font-size: 14px;
  padding-top: 12px;
  font-family: poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-left: 5px;
}

.slogan:hover {
  color: #f8ca7a;
  text-transform: uppercase !important;
}
.lien {
  color: black;
  text-decoration: none;
  margin-right: 50px;
  margin-left: 30px;
  font-size: 14px;
  font-family: poppins, sans-serif;
}
.lien a {
}
.lien:hover {
  color: #f8ca7a;
}

.iconeCall {
}

.iconeEmail {
  margin-right: 5px;
}

@media (max-width: 792px) {
  .Topbar {
    display: none !important;
  }
}
