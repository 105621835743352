.navba {
  background-color: antiquewhite;
  padding: 0 !important;
}
.logo {
}
.logo:hover {
  color: #95191c;
}
.carousel-slide {
  margin-top: none;
}

.logo {
  color: #95191c;
}
.parti1_logo {
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
}
.parti2_logo {
  text-transform: uppercase;
  font-weight: 100;
}
.parti3_logo {
  display: block;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 14px;
  margin-left: 20px;
}
.logo_atcc {
  height: 100px;
  width: 130px;
}

#collasible-nav-dropdown {
  /* color : #C03A1E; */
  padding-left: 10px;
  color: #6f8cfb;
}
#collasible-nav-dropdown:hover {
  color: #c7181b;
}
.dropdown-menu.show {
  /* color: #95191c;  */
}
.dropdown-item:hover {
  /* background-color: #c90e11; */
  transition: 0.5s;
}
#responsive-navbar-nav {


   margin-left: 50px;
}
.navbar-toggler {
  color: black;
}
.navbar-toggler-icon {
  color: black;
}
.liens {
  text-decoration: none;
  color: #6f8cfb;
  font-weight: 500;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0 10px;
}

.container {
  width: 100% !important;
  margin: 5px auto !important;
  padding: 0 !important;
}
.liens:hover {
  color: #c90e11;
}

.me-auto {
  margin-left: 150px;
  width: 90%;
}
.nav-link {
  font-size: 16px;
  color: #0c0101bd;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.nav-link:hover {
  color: rgb(204, 39, 17);
}

@media (max-width: 1200px) {
  .me-auto {
    margin-left: 50px;
    width: 100%;
  }
}
