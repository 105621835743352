.section-services {
  display: block;
  width: 100%;
  /* height: 100vh; */
  text-align: center;
  margin-top: 40px;
}
.section-services h1 {
  font-size: 45px;
  font-family: "relaway", sans-serif;
  color: #fcb078;
  font-weight: bold;
}

.cadre-services {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  padding-bottom: 3vh;
}
.service-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}


.bloc-button {
  width: 100%;
}

.card-title {
  font-family: "relaway", sans-serif;
  padding-top: 15px;
  font-size: 25px;
  color: #fcb885;
  font-weight: bold;
}
.card-text {
  font-size: 18px;
  font-family: "relaway", sans-serif;
  color: black;
}
.button {
  border: 1px solid #fda563;
  color: black;
  text-decoration: none;

  padding: 10px 10px;
  border-radius: 50px;
  border-color: #fda563;


  /* border-color: rgb(194, 11, 11) !important; */
}

.button:hover {
  color: #fff;
  background-color: #fda563;
}



@media (max-width: 768px) {
  .service-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
}
