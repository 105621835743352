.blocBaseApropos{
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    text-align: justify;
    padding: 10px 5px;

}


.bloc_image{
    margin: 15px 5px;
    padding-left: 50px;
}
.bloc_image2{
    margin-top: 60px;
    padding-left: 50px;
}
.bloc_texte{
    margin: 15px 5px;
    padding: 50px 0px 0 0px;
}
.bloc_texte{
    margin: 15px 5px;
    padding: 50px 25px 0px 25px;
    color: #444444;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.bloc_texte2{
    color: #444444;
    font-size: 16px;
    font-family: 'open sans', sans-serif;
    margin: 15px 5px;
}
.bloc_texte2 h3{
    font-weight: 700;
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
    color: #213b52;
    margin-bottom: 24px;
}
.bloc_texte h3{
    font-weight: 700;
    font-size: 34px;
    font-family: 'relaway', sans-serif;
    color: #FCB481;
    margin-bottom: 24px;
}
.bloc_texte p{
  color: black;
}

.bloc_cibles{
    /* border: 2px solid yellow; */
    display: block;
    font-family: 'Roboto', sans-serif;

    padding-bottom: 10px;
}
.titre_cibles{
    /* border: 2px solid black; */
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 25px 0;
}
.titre_cibles h2{
    font-size: 35px;
    font-style: inherit;
    font-weight: 600;
    color: #FCAC6E;
}

.bloc_cadre_cibles{

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 70px;
}
.h3_apropos{
    margin-top: 25px;
}

.cadre_bloc{
    padding-left: 15px;
    padding-top: 10px;
    background-color: white;
    border: solid;
    border-color: #FCAC6E;
}
.cadre_bloc h4{
    margin: 5px 0;
    color: #FCAC6E;
    text-align: center;
}
.cadre_bloc p{
  color: black;
  text-align: center;
}
.cadre_bloc:hover{
    background-color: transparent;
    transition: 0.4s;
    color: #fff;
}

@media (max-width: 320px) {
    .blocBaseApropos{
        width: 100%;
    }
    .card_img{
      width: 20rem;
      padding-left: 1.5rem;
    }
    .bloc_texte{
        width: 18rem;
        padding-left: 1.5rem;

    }
    .bloc_texte h3{
        font-size: 25px;
    }
    .cadre_bloc{
        margin-left: 0rem;
        width: 100%;
    }
}

.icone-cible{
  width: 50px;
}

.button {
  border: 1px solid #FDA563;
  color: black;
  text-decoration: none;
  margin: 5px 0;
  padding: 10px 10px;
  border-radius: 50px;
  border-color: #FDA563;

  /* border-color: rgb(194, 11, 11) !important; */

}


.button:hover {
  color: #fff;
  background-color: #FDA563;

}
