* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.body {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.footer-globale {
  background-color: antiquewhite;
  position: relative;
  width: 100%;
  min-height: 350px;
  padding: 1rem 1rem;
}

.footer-social{

}
.footer-container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.col {
  min-width: 250px;
  color: #4c74fd;
  font-family: poppins;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.col .contact-details{

}

.footer-logo {
  /* width: 100px; */
  /* margin-bottom: 10px; */
}
.col h3 {
  color: #f88e4a;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}


.col h3::after {
  content: "";
  height: 3px;
  width: 0px;
  background-color: #fe812d;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s ease;
}

.col h3:hover::after {
  width: 30px;
}

.col .footer-social a {
  color: #fe812d;
  margin-top: 2rem;
  margin-right: 5px;
  transition: 0.3s ease;
}

.col .footer-social a:hover {
  transform: scale(1.5);
  filter: grayscale(25);
}

.col .links a {
  display: block;
  text-decoration: none;
  color: #4c74fd;
  margin-bottom: 5px;
  position: relative;
  transition: 0.3s ease;
}
.col .links a:before {
  content: "";
  height: 16px;
  width: 3px;
  position: absolute;
  top: 5px;
  left: -10px;
  background-color: #fe812d;
  transition: 0.5s ease;
  opacity: 0;
}

.col .links a:hover::before {
  opacity: 1;
}

.col .links a:hover {
  transform: translateX(-8px);
  color: #fe812d;
}
.col .contact-details {
  display: inline-flex;
  justify-content: start;
}
.col .contact-details a {
  margin-right: 15px;
}

.row.form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
.row.form input {
  background-color: #fe812d;
  border: 0;
  outline: none;
  padding: 14px 20px;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form button {
  padding: 14px 20px;
  border: 0;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fe812d;
}

/******* Responsive Design************/

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .col {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.footer_bloc2 {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding-bottom: 25px;
}
.footer_bloc2 h6 {
  margin-right: 150px;
}
.codeur a {
  color: rgb(49, 4, 4);
  font-weight: 600;
  text-decoration: none;
  font-size: 18px;
}
.codeur a:hover {
  color: #4c74fd;
}

/* Pour les écrans de taille moyenne (par exemple, tablettes) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .col {
    width: 30%;
    /* Ajustez la largeur selon vos besoins */
  }
}

/* Pour les écrans de taille petit (par exemple, tablettes) */
@media screen and (max-width: 769px) {
  .col {
    min-width: 250px;
    color: #4c74fd;
    font-family: poppins;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Pour les écrans de grande taille (par exemple, ordinateurs de bureau) */
@media screen and (min-width: 1025px) {
  .col {
    width: 25%;
    /* Ajustez la largeur selon vos besoins */
  }
}
