
.famille_bloc1{
    display: flex;
    text-align: center;
    margin-top: 5vh;
    padding-bottom: 50px;
    height: 50vh;
    padding-left: 30vh;


}


.famille_bloc1_1{
    background-color: rgba(211, 211, 211, 0.445);
    height: 25vh;
    width: 50%;
    margin-top: 53px;
    margin-right: 50px;

}
.famille_bloc1_1 h3{
    font-size: 45px;
    margin-top: 50px;
    color:  #C03A1E;
}

.famille_bloc1_2{
    height: 45vh;
    width: 25%;
}
.famille_bloc1_2 img{
    margin-top: 50px;
    margin-left: 25vh;
}

.h2_centre{
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 80px;
    font-size: 40px;
    margin-left: 50px;
    text-decoration: underline;
    color: #FCAC68;
}

.entreprise_bloc2{
    height: auto !important;
    display: flex;
    justify-content: space-evenly;



    padding: 25px 10vh;
}
.img_illust{
  /* width: 40%; */
}

.contenu_service{
  width: 50%;
    text-align: center;
    margin-top: 5vh;


}
.text-banner {
  color: white;
}
.banner-container{
  width: 10;
}


.contenu_service h3{
    margin-bottom: 50px;

}
.title-consulting {
  color: #8098F3;
}
.contenu_service p{
    font-size: 20px;
}
.contenu_service .prix-formation{
  color: blue;
}
.contenu_service .frais-examen{
  color: blue;
}
.newDiv{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.button_souscript{
  width: 12rem;
  background-color:#fff;
  border-color: #ad1013;
  color: #cc1013;
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  /* margin-top: -110px; */
}
.button_souscript:hover{
  background-color: #c70d10 ;
  border-color: #c70d10;
  color: #fff;
}
.button_souscript:active{
  background-color: #c70d10 ;
  border-color: #c70d10;
  color: #fff;
}
.btn-primary{
  --bs-btn-active-bg:  #c70d10;
  --bs-btn-active-border-color: #c70d10;
}

  @media (max-width: 1018px) {
    .famille_bloc1 {
      display: flex;
      padding: 0 0;
   }
    .famille_bloc1_1 {
        /* display: flex;
        flex-wrap: wrap;
        border: 2px solid red; */
        display: none;
    }
    .h2_centre{
      margin-top: 5vh;
      /* padding-top: 20vh; */
    }

    .famille_bloc1_2{
      display: block;
      width: 100%;
    }
    .famille_bloc1_2 img{
      width: 100%;
      margin-bottom: 1rem;
      max-width: 200px;
      margin: 0 auto;
      display: block;
    }
    .famille_bloc1_1{
        background-color: rgba(211, 211, 211, 0.445);
        height: auto;
        width: auto;
        padding: 0;
        /* margin-top: 53px;
        margin-right: 50px;
        display: inline-block; */
    }
    .h2_centre{
      padding-right: 50px;
      font-size: 35px;
    }

    .famille_bloc1_2 img {
      width: 80%;
    }

    .entreprise_bloc2 {
      flex-direction: column;
    }

    .img_illust img {
      width: 100%;
      margin-bottom: 1rem;
      max-width: 200px;
      margin: 0 auto;
      display: block;
    }

    .contenu_service {
      width: 100%;
    }
  }

  @media (max-width: 375px) {
    .famille_bloc1_2{
      /* border: 2px solid red; */
      width: 50%;
      height: 20vh;
      display: flex;
      margin-left:6rem;
  }
  .h2_centre{
    font-size: 25px;


  }
  .famille_bloc1{
    height: 14rem;
  }
  }

  @media (max-width: 320px) {
    .famille_bloc1_2{
      /* border: 2px solid red; */
      width: 50%;
      height: 20vh;
      display: flex;
      margin-left:5rem;
  }
  .h2_centre{
    font-size: 10px;

  }
  .famille_bloc1{
    height: 10rem;
  }
  .contenu_service h3{
    margin-bottom: 50px;
    padding-left: -2rem;

}
  }
