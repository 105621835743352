/* Styles par défaut */
.NavNav {
  background-color: antiquewhite;
}

.logo-global {
  display: flex;
  align-items: center;
  margin-top: -20px;
}

.logo {
  margin-right: 10px;
  margin-left: -50px;
}
.logo-text{
  margin-bottom: 6px;
}

.me-auto {
  margin-left: 20px;
}


.listItem {
  color: #FCAA61;
}

/* Médias queries pour la réactivité */
@media (max-width: 768px) {

  /* Styles pour les écrans de petite taille (jusqu'à 768px de large) */
  .me-auto {
    margin-left: 0;
    /* Réduisez la marge à zéro pour économiser de l'espace */
  }
}

@media (max-width: 576px) {

  /* Styles pour les écrans de très petite taille (jusqu'à 576px de large) */
  .logo {
    margin-left: 0;
    /* Réduisez la marge à zéro pour économiser de l'espace */
  }

  .rdv {
    margin-left: 0;
    /* Réduisez la marge à zéro pour économiser de l'espace */
  }
}
