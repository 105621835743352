.container{
  width: 80%;
  margin: 50px auto;
  padding: 20px;
}

.title{
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 70px;
}

.about-content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.image{
  flex: 1;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 10px;
}
.image img{
  width: 100%;
  transition: transform 0.3s ease;
}
.image:hover img{
  transform: scale(1.1);
}

.content{
  flex: 1;
  margin-left: 20px;
}
.content p{
font-size: 20px;
line-height: inline-block;
}

.readmore{
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: brown;
  font-size: 17px;
  text-decoration: none;
  border-radius: 10px;
  color: #fff;
  transition: 0.3s ease;
}
.readmore:hover{
background-color: aquamarine;
}

@media screen and (max-width: 768px) {
  .about-content{
    flex-direction: column;
  }
  .image, .content{
    margin: 0;
    margin-bottom: 20px;
  }
  .title{
    font-size: 38px;
  }
}
