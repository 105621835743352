.consultingConteneur {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.consultingHeader {
  /* border: 2px solid yellow; */
  padding: 0.5rem 0px;
  /* height: auto; */
  width: 80%;
  margin: 1rem auto;
}

.consultingBlockService {
  /* border: 2px solid yellow; */
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 5px 0; */
  /* height: 400px; */
}

.consultingServiceContenu {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 5px;
  height: 100%;
}

.consultingServiceContenu div:first-child {
  /* background-color: #ce420a; */
  border-radius: 10px;
  width: 35%;
  height: 400px;
}

.consultingServiceContenu div:last-child {
  padding: 1rem 0.5rem;
  border-radius: 10px;
  overflow-y: auto;
  width: 55%;
  height: 400px;
}

.pagination {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

@media (max-width: 893px) {
  .consultingConteneur {
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .consultingServiceContenu {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* height: 100%; */
  }

  .consultingServiceContenu div:first-child {
    /* background-color: #ce420a; */
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    height: 300px;
  }

  .consultingServiceContenu div:last-child {
    /* background-color: #0ace3b; */
    overflow-y: auto;
    width: 100%;
    padding: 10px 5px;
    height: 500px;
    margin: 0 auto;
  }
}
.prix-formation{
  color: blue;
}
.prix-examen {
  color: blue;
}
