.MuiGrid2-root{
    padding: 17px 17px !important;
}

.MuiInputBase-root:hover{
    border-color: #ad1013 !important
}
/* #outlined-basic1:hover{
   ;

} */
standard-textarea:focus{
    color:  maCouleur;
}

.card-body{
    padding: 0 0;
}

.contactAll{
    display: block;

}

.hidden {
  display: none;
}
.topContact{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;

    text-align: center;
    width: 100%;
    background-color:rgba(245, 244, 244, 0.781);
    padding: 10px 0;
}


.topContact h2{
    color: #fda563;
}

.topContact ul {
  list-style: none;
  font-size: 18px;

}

.topContact ul li {
    display: inline-block;
}

.lienAccueil{
   text-decoration: none;
   color: black;
   margin-right: 5px;
}
.lienAccueil:hover{
    color: #c40e11;
}

.miniParaph {
    display: flex;
    justify-content: left;
    padding-top: 35px;
    color: #fda563;
}
.miniParaph span{
  font-size: 40px;
  font-weight: 800;
  margin-left: 25px;
  margin-bottom: 25px;

}
.formulaire{
    /* border: 2px solid orchid; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 15vh;
}

.body_card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}
.imageSecretaire{

    max-width: 100%;
    height: auto; /* Hauteur automatique en fonction de la largeur */
    /* margin-left: 15px; */
}

.imageSecretaire img{
    max-width: 100%; /* Définit une largeur maximale de 100% pour l'image */
    height: auto;
}
.blocFornulaire{
    max-width: 100% ;
    height: auto;
    margin-right: 15px;
}
#basic-addon1{
    background-color: #c70d10;
    border: 0 solid #c70d10 ;
    color: rgb(248, 248, 248);
}

.form-control{
    width: 50%;
}

.infosEntreprise{
    /* border: 2px solid #c70d10 ; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 70px;
}
.grids{
    margin-top: 50px;
}
.infosEntreprise1{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}
.infosEntrepriseIcone{
  display: flex;
  justify-content: space-between;
}
.infosEntrepriseIcone h3{
   padding-left: 5px;
}
.infosEntrepriseInfos{
    text-align: center;
}
.infosEntrepriseInfos span{
    margin-left: 45px;
}
.form-control:focus{
    box-shadow: none !important;
    border-color: #c40e1180;
}
.bouton_soumettre{
    width: 10rem;
    background-color:#fff;
    border-color: #fda563;
    color: black;
    font-size: 17px;
    float: left;
    font-weight: 600;
    text-transform: capitalize;
    /* margin-top: -110px; */
}
.bouton_soumettre:hover{
    background-color: #fda563 ;
    border-color: #fda563;
    color: #fff;
}
.bouton_soumettre:active{
    background-color: #c70d10 ;
    border-color: #c70d10;
    color: #fff;
}
.btn-primary{
    --bs-btn-active-bg:  #c70d10;
    --bs-btn-active-border-color: #c70d10;
}
.numero{
    margin-left: 2.5rem;
}

.infosEntreprise .loc {
  color: #fda563 !important;
}
@media(max-width: 889px){

        .body_card{
            display: flex;
            justify-content: space-around;
            flex-direction: column;

        }
        .mb-3{
            display: flex;
            flex-direction: row;
            width: 25rem;
        }
        .grids{
            text-align: center;

        }
        .MuiGrid2-root{
            padding: 20px 20px !important;
        }
        .grid_bouton{

            margin-left: 120px;
        }

}
.mail-contact{
 margin-left: -50px;
}

@media(max-width: 320px){

    .card-body{
        width: 200px;
    }
    .grid_bouton{
        margin-left: 30px !important;
    }
    .miniParaph span{
        font-size: 1.5rem;
    }
    .card{
        width: 200px;
        text-align: center;
        /* margin-left: 60px; */
    }


}

@media(max-width: 1051px){
    .infosEntreprise{
        /* border: 2px solid #c70d10 ; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: -20px;
        margin-bottom: 70px;
        height: 60vh;
        font-size: 17px;
    }
     Form{
       margin-top: 45px;
    }
    .MuiGrid2-root{
        padding: 20px 20px !important;
    }

    .grids{
        display: flex;
        flex-direction: column;

    }
    .bouton_soumettre{
        margin-left: 10px;

    }
    .grid_bouton{
        margin-left: 120px;
        margin-top: -50px;
    }


}
