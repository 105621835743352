.container-objectif {
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 70px;
  font-family: 'relaway', sans-serif;
  color: #FCB078;

}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.image {
  flex: 1;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 10px;
}

.image img {
  width: 100%;
  transition: transform 0.3s ease;
}

.image:hover img {
  transform: scale(1.1);
}

.content {
  flex: 1;
  margin-left: 20px;
  text-align: center;
  padding: 20px;
}

.content p {
  font-size: 20px !important;

  line-height: inline-block;
  color: black;
}

.button {
  border: 1px solid #FDA563;
  color: black;
  text-decoration: none;
  margin: 5px 0;
  padding: 10px 10px;
  border-radius: 50px;
  border-color: #FDA563;

  /* border-color: rgb(194, 11, 11) !important; */

}


.button:hover {
  color: #fff;
  background-color: #FDA563;

}


.readmore:hover {
  background-color: aquamarine;
}

@media screen and (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .image,
  .content {
    margin: 0;
    margin-bottom: 20px;
  }

  .title {
    font-size: 38px;
  }
}
