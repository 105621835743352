/* Banner.css */

.banner-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  /* Ajustez la largeur maximale selon vos besoins */
  margin: 0 auto;
  margin-bottom: 50px;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
}

.banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  /* Couleur de fond du titre */
  color: #7590F4;
  /* Couleur du texte du titre */
  padding: 10px 20px;
  font-size: 24px;
  /* Taille de police du titre */
  font-weight: bold;
  /* Style de police du titre */


}
