.mediaHeader {
  height: 100vh;
  margin-top: appTopMargin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;


  .viewportHeader {
    .header {
      text-align: center;

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;

        div {
          width: 300px;
        }
      }

      h1 {
        margin: auto;
        color: white;
        text-align: center;
        line-height: 1.5;
        font-size: 4.5rem;
      }
      line-height: 1;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mediaHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover ;
    background-repeat: no-repeat;
    background-position: center;



    .viewportHeader {
      .header {
        padding: 30px 0;

        .actions {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 auto;

          div {
            width: 100%;
          }
        }

        h1 {
          font-size: 3rem;

        }

        font-weight: 600;
      }
    }
  }
}

.header h1{
font-size: 56px !important;
}
@media only screen and (min-device-width: 480px) and (max-device-width: 1080) {
  img {
    display: none;
  }
}

.header h1 {
  color: #D08944;
  text-align: start;
font-size: 38px;
text-align: center;
}

.banner-bouton {
  background-color: #fcab65;
  /* Couleur de fond du bouton */
  color: white;
  /* Couleur du texte du bouton */
  padding: 10px 20px;
  /* Rembourrage intérieur du bouton */
  border: none;
  /* Supprime la bordure du bouton */
  border-radius: 5px;
  /* Coins arrondis */
  text-decoration: none;
  /* Supprime la décoration du lien dans le bouton */
  cursor: pointer;
  /* Curseur de type main lorsque vous survolez le bouton */
  margin-right: 10px;
  /* Marge à droite pour espacer les boutons */
  border-radius: 50px;
  border-color: blue;
}

/* Style au survol du bouton */
.banner-bouton:hover {
  background-color: #8b9ef0;
  /* Changement de couleur de fond au survol */
}

/* Style spécifique pour le premier bouton */

/* Style spécifique pour le texte des boutons */
.banner-bouton a {
  color: #fff;
  text-decoration: none;
}
.banner-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 150px;
}
.banner-container {
  margin-bottom: 0px;
  /* Vous pouvez ajuster cette valeur selon vos besoins */
}
