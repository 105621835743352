@import url("https://fonts.googleapis.com/css?family=Roboto");
.footer {
  background-color: #95191c;
  color: #fff;
  padding-top: 5vh;
}

.barrefootertop_cards {
  margin: 5px 0;
}
.footer_bloc1 {
  text-align: center;
  padding-bottom: 45px;
}
.footer_bloc1 h3 {
  font-family: Roboto, Verdana, sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}
.footer_bloc1_interne {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
}
.body_cadre {
  text-align: left;
  margin: 10px 0;
}
.body_cadre h5 {
  font-family: Roboto, Verdana, sans-serif;
  margin-left: 25px;
  font-size: 25px;
}
.body_cadre li a {
  text-decoration: none;
  color: #fff;
  margin: 25px 0;
}
.body_cadre a:hover {
  color: black;
}
.body_cadre li::marker {
  color: black;
}
.body_cadre ul {
  list-style: disc;
}

hr {
  font-size: 15px;
  color: black;
}

.footer_bloc2 {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding-bottom: 25px;
}
.footer_bloc2 h6 {
  margin-right: 150px;
}
.codeur a {
  color: rgb(49, 4, 4);
  font-weight: 600;
  text-decoration: none;
  font-size: 18px;
}
.codeur a:hover {
  color: rgb(247, 241, 241);
}
.logoPlatinium {
  margin-top: -15px;
}
@media (max-width: 893px) {
  .footer_bloc1_interne {
    display: flex;
    flex-direction: column;
    height: 90vh;
    font-size: 17px;
    justify-content: center; /* added */
    align-items: center; /* added */
  }
  .logoPlatinium {
    margin-right: 250px;
    width: 210px;
    height: auto;
  }
}
